
import React, { useEffect, useState, useContext } from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { BASE_URL } from '../utils/Constants'
import { apiRequestPOST } from '../utils/ApiCall'
import { toast } from 'react-toastify'
import GoogleMapReact from 'google-map-react';
import get from 'lodash.get'
import Geocode from "react-geocode";
import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup';
import { userDetailContext } from '../App';
import { useHistory } from "react-router-dom";

export default function My_address() {
   let history = useHistory();
   const [deliveryAdress, setDeliveryAdress] = useState([]);
   const [googleAdress, setGoogleAdress] = useState([]);
   const [googleLangititude, setGoogleLangititude] = useState([]);
   const [googleLongtitude, setGoogleLongtitude] = useState([]);
   const [editGoogleAdress, setEditGoogleAdress] = useState([]);
   const [editGoogleLangititude, setEditGoogleLangititude] = useState(false);
   const [editGoogleLongtitude, setEditGoogleLongtitude] = useState(false);
   const [editDeliveryAdress, setEditDeliveryAdress] = useState([]);
   const [tab, setTab] = useState('addresslist')
   const [edittab, setEditTab] = useState('addresslist')
   const [registarionButton, setRegistarionButton] = useState('false')
   const [currentState, setCurrentState] = useState({
      langititude: 10.526505634649208,
      longtitude: 76.21396229150965
   })
   const [editCurrentState, setEditCurrentState] = useState([])
   const center = {
      lat: 10.526505634649208,
      lng: 76.21396229150965
   }

   const center1 = {
      //   lat:editGoogleLangititude,
      //   lng:editGoogleLongtitude  
      lat: parseFloat(editGoogleLangititude),
      lng: parseFloat(editGoogleLongtitude)
   }
   const zoom = 11
   const AnyReactComponent = ({ text }) => <img style={{ width: 20, height: 'auto' }} src='assets/img/marker.png' />;
   const getlocalNameByCoord = (langititude, longtitude) => {
      
      Geocode.setApiKey("AIzaSyD_ivQdtQK5UuTcspqtw5u5_r27Xbi_f9w");

      Geocode.setLanguage("en");

      Geocode.setRegion("es");

      Geocode.setLocationType("ROOFTOP");

      Geocode.enableDebug();
      {(langititude && longtitude )&&
       
      Geocode.fromLatLng(langititude,longtitude).then(
         (response) => {
            const address = response.results[0].formatted_address;
            console.log("address", address);
            setGoogleAdress(address)
            setGoogleLangititude(langititude)
            setGoogleLongtitude(longtitude)
            setEditGoogleAdress(address)
            setEditGoogleLangititude(langititude)
            setEditGoogleLongtitude(longtitude)
            console.log(googleLangititude);
            console.log(googleLongtitude);
         },
         (error) => {
            console.error(error);
         }
      )
      }
   }

const logout= () => { 
   localStorage.removeItem('userName') 
   localStorage.removeItem('userCartist')
   localStorage.removeItem('userCartiststore_id')
   localStorage.removeItem('userCartistbranch_id')
   localStorage.removeItem('orderlistid')
   localStorage.removeItem('productId')
   localStorage.removeItem('userToken')
   history.push({
      pathname: "/home",
      state: ''
   }) 
}
const location = useLocation();
useEffect(() => {
      apiRequestPOST(`${BASE_URL}app_user_ctrl/get_delivery_address`, '', false)
         .then((response) => {
            setDeliveryAdress(response)
         }, (error) => {
            console.log(error);
         });

   }, [location])

   const phoneRegExp = /^!*([0-9]!*){10,}$/

   const registrationSchema = Yup.object().shape({
      address: Yup.string().required('Required'),
      name: Yup.string().required('Required'),
      phonenumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Required'),
      place: Yup.string().required('Required'),
   });

   var contextData = useContext(userDetailContext);
   return (
      <React.Fragment>
         <Header />

         <section className="account-page section-padding">
            <div className="container">
               <div className="row">

                  <div className="col-lg-9 mx-auto">
                     <div className="row no-gutters">
                        <div className="col-md-4">
                           <div className="card account-left">
                              <div className="user-profile-header">
                                 {/* <img alt="logo" src="assets/img/user.jpg"/> */}
                                 <h5 className="mb-1 text-secondary"><strong>Hi </strong> {contextData?.username?.name}</h5>
                                 <p> {contextData?.username?.mob}</p>
                              </div>
                              <div className="list-group">
                                 <Link to="/my_profile" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-account-outline"></i>  My Profile</Link>
                                 <Link to="/my_address" className="list-group-item list-group-item-action active"><i aria-hidden="true" className="mdi mdi-map-marker-circle"></i>  My Address</Link>
                                 <Link to="/wishlist" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-heart-outline"></i>  Wish List </Link>
                                 <Link to="/rating" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-star-outline"></i>  Store Rating </Link>
                                  <Link to="/orderlist" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-format-list-bulleted"></i>  Order List</Link>
                                 <Link   className="list-group-item list-group-item-action"
                                 onClick={()=>logout()}><i aria-hidden="true" className="mdi mdi-lock"></i>  Logout</Link>
                              </div>
                           </div>
                        </div>

                        <div className="col-md-8" style={(edittab == 'addresslist') && (tab == 'addresslist') ? { display: "block" } : { display: "none" }}>
                           <div className="row no-gutters">
                              {(deliveryAdress.length == 0) &&

                                 <img className="rounded img-fluid" src="assets/img/empty.png" alt="Logo" />


                              }
 
                              {deliveryAdress.length > 0 && deliveryAdress.map(item => {
                                 return (

                                    <div className="col-md-6">
                                       <div className="product">

                                          <div className="product-body" style={{ height: 80 }}  >
                                             <h5 style={{ fontWeight:'bold',color: item.address_status == 'true' ? 'green' : '' }}>{item.delivery_fname}{item.address_status == 'true' ? '*' : ''}</h5>
                                             <h6 style={{color:'#000'}}><strong>{item.delivery_address}</strong></h6>
                                             <h6 style={{color:'#000'}}><strong><img src="assets/img/phone.png" alt="logo"  style={{width:'18px',marginRight:'8px'}}/>{item.delivery_phone}</strong></h6>
                                          </div>
                                          <div className="product-footer">
                                             <center>
                                                <span style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }} onClick={() => {
                                                   setEditTab(''); 
                                                   setEditDeliveryAdress(item); 
                                                   setEditGoogleAdress(item.delivery_address)
                                                   setEditGoogleLangititude(item.latitude)
                                                   setEditGoogleLongtitude(item.longitude)
                                                }}>EDIT</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                                <span style={{ color: "red", fontWeight: 'bold', cursor: 'pointer' }}
                                                   onClick={() => {
                                                      apiRequestPOST(`${BASE_URL}app_user_ctrl/delete_user_address`, { id: item.id }, false)
                                                         .then((response) => {
                                                            if (response == 1) {
                                                               setRegistarionButton({ val: "" })
                                                               apiRequestPOST(`${BASE_URL}app_user_ctrl/get_delivery_address`, '', false)
                                                                  .then((response) => {
                                                                     setDeliveryAdress(response)
                                                                  }, (error) => {
                                                                     console.log(error);
                                                                  });
                                                               toast.success('Delete Successfully', {
                                                                  position: "top-center",
                                                                  autoClose: 5000,
                                                                  hideProgressBar: false,
                                                                  closeOnClick: true,
                                                                  pauseOnHover: true,
                                                                  draggable: true,
                                                                  progress: undefined,
                                                               });
                                                            } else {
                                                               toast.error('Delete Failed', {
                                                                  position: "top-center",
                                                                  autoClose: 5000,
                                                                  hideProgressBar: false,
                                                                  closeOnClick: true,
                                                                  pauseOnHover: true,
                                                                  draggable: true,
                                                                  progress: undefined,
                                                               });
                                                            }

                                                         }, (error) => {
                                                            console.log(error);
                                                         }).finally(() => {
                                                         });
                                                   }}
                                                >DELETE</span>
                                             </center>
                                          </div>

                                       </div>
                                    </div>




                                 )
                              })
                              }
                              <div className="col-md-12">
                                 <center ><br />
                                    <button type="button" className="btn btn-success btn-lg" onClick={() => {
                                       setTab('')
                                    }}> Add New </button>
                                 </center>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-8 fff" style={(tab == 'addresslist') ? { display: "none" } : { display: "inherit" }}>
                           <div className="card card-body account-right">
                              <div className="widget">
                                 <div className="section-header">
                                    <h5 className="heading-design-h5">
                                       Delivery Address
                                    </h5>
                                 </div>


                                 {<Formik
                                    initialValues={{
                                       name: '',
                                       address: googleAdress,
                                       langititude: googleLangititude,
                                       longtitude: googleLongtitude,
                                       phonenumber: '',
                                       place: '',
                                       address_statuss: '',
                                    }}
                                    enableReinitialize
                                    validationSchema={registrationSchema}
                                    onSubmit={values => {
                                       setRegistarionButton({ val: "disabled" })
                                       apiRequestPOST(`${BASE_URL}app_user_ctrl/add_user_address`, values, false)
                                          .then((response) => {
                                             if (response == 1) {
                                                document.getElementById('closee').click()
                                                setRegistarionButton({ val: "" }) 
                                                setEditTab('addresslist');
                                                setTab('addresslist');
                                                apiRequestPOST(`${BASE_URL}app_user_ctrl/get_delivery_address`, '', false)
                                                   .then((response) => {
                                                      setDeliveryAdress(response)
                                                   }, (error) => {
                                                      console.log(error);
                                                   });
                                                toast.success('Process Successfully', {
                                                   position: "top-center",
                                                   autoClose: 5000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                });
                                             } else {
                                                toast.error('Process Failed', {
                                                   position: "top-center",
                                                   autoClose: 5000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                });
                                             }

                                          }, (error) => {
                                             console.log(error);
                                          }).finally(() => {
                                             setRegistarionButton({ val: "" })
                                          });

                                       // console.log(values);
                                    }}
                                 >
                                    {({ errors, touched, values }) => (
                                       <Form>
                                          <div className="row">
                                             <div className="col-sm-12">
                                                <div style={{ height: '40vh', width: '100%' }}>
                                                   <GoogleMapReact
                                                      //   onClick={getlocalNameByCoord}
                                                      bootstrapURLKeys={{ key: 'AIzaSyD_ivQdtQK5UuTcspqtw5u5_r27Xbi_f9w' }}
                                                      defaultCenter={center}
                                                      defaultZoom={zoom}
                                                      onDrag={(e) => {

                                                         setCurrentState({
                                                            langititude: e.center.lat(),
                                                            longtitude: e.center.lng(), 
                                                         })
                                                      }}
                                                      onGoogleApiLoaded={async (e) => {
                                                         await getlocalNameByCoord(currentState.langititude, currentState.longtitude)
                                                       }}
                                                      onDragEnd={async (e) => {
                                                        await getlocalNameByCoord(currentState.langititude, currentState.longtitude)
                                                      }}
                                                   >
                                                      <AnyReactComponent
                                                         lat={currentState.langititude}
                                                         lng={currentState.longtitude}
                                                         text="My Marker"
                                                      />
                                                   </GoogleMapReact>
                                                </div>
                                                <label className="control-label">Address <span className="required">*</span></label>
                                                <Field className="form-control border-form-control" value={values.address} placeholder="Address" name='address' type="hidden" />
                                                <Field className="form-control border-form-control" value={values.address} placeholder="Address"   type="text" disabled />
                                                {errors.address && touched.address ? (<div style={{ color: 'red' }}>{errors.address}</div>) : null}
                                             </div>
                                          </div>

                                          <div className="row">
                                             <div className="col-sm-12">
                                                <div className="form-group">
                                                   <Field type="hidden" name="langititude" value={values.langititude} />
                                                   <Field type="hidden" name="longtitude" value={values.longtitude} />
                                                   <label className="control-label">Name <span className="required">*</span></label>
                                                   <Field className="form-control border-form-control" placeholder="Name" type="text" name="name" />
                                                   {errors.name && touched.name ? (<div style={{ color: 'red' }}>{errors.name}</div>) : null}
                                                </div>
                                             </div>
                                          </div>

                                          <div className="row">
                                             <div className="col-sm-12">
                                                <div className="form-group">
                                                   <label className="control-label">House Name/Number <span className="required">*</span></label>
                                                   <Field className="form-control border-form-control" name="place" placeholder="House Name/Number" type="text" />
                                                   {errors.place && touched.place ? (<div style={{ color: 'red' }}>{errors.place}</div>) : null}
                                                </div>
                                             </div>
                                          </div>


                                          <div className="row">
                                             <div className="col-sm-12">
                                                <div className="form-group">
                                                   <label className="control-label">Phone Number <span className="required">*</span></label>
                                                   <Field className="form-control border-form-control" placeholder="Phone Number" name="phonenumber" type="text" />
                                                   {errors.phonenumber && touched.phonenumber ? (<div style={{ color: 'red' }}>{errors.phonenumber}</div>) : null}
                                                </div>
                                             </div>
                                          </div>

                                          <div className="custom-control custom-checkbox mb-3">
                                             <Field type="checkbox" name='address_statuss' className="custom-control-input" id="customCheckbills"
                                             />
                                             <label className="custom-control-label" htmlFor="customCheckbills">Use my default address</label>
                                          </div>


                                          <div className="row">
                                             <div className="col-sm-12 text-right">
                                                <button type="button" className="btn btn-danger btn-lg" onClick={() => {
                                                   setTab('addresslist')
                                                }}> Cancel </button> &nbsp;
                                                <button type="submit" disabled={(registarionButton.val) ? "disabled" : ""} className="btn btn-success btn-lg"> Add Address </button>
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik>
                                 }
                              </div>
                           </div>
                        </div>


                        <div className="col-md-8 fff" style={(edittab == 'addresslist') ? { display: "none" } : { display: "inherit" }}>
                           <div className="card card-body account-right">
                              <div className="widget">
                                 <div className="section-header">
                                    <h5 className="heading-design-h5">
                                       Update  Delivery Address
                                    </h5>
                                 </div>


                                 {<Formik
                                    initialValues={{
                                       id: editDeliveryAdress.id,
                                       name: editDeliveryAdress.delivery_fname,
                                       address: editGoogleAdress,
                                       langititude: editGoogleLangititude,
                                       longtitude: editGoogleLongtitude,
                                       phonenumber: editDeliveryAdress.delivery_phone,
                                       place: editDeliveryAdress.delivery_place,
                                       address_status: editDeliveryAdress.address_status,
                                    }}
                                    enableReinitialize
                                    validationSchema={registrationSchema}
                                    onSubmit={values => {
                                       setRegistarionButton({ val: "disabled" })
                                       apiRequestPOST(`${BASE_URL}app_user_ctrl/update_user_address`, values, false)
                                          .then((response) => {
                                             if (response == 1) {
                                                document.getElementById('closee').click()
                                                setRegistarionButton({ val: "" })
                                                apiRequestPOST(`${BASE_URL}app_user_ctrl/get_delivery_address`, '', false)
                                                   .then((response) => {
                                                      setDeliveryAdress(response)
                                                   }, (error) => {
                                                      console.log(error);
                                                   });
                                                toast.success('Update Successfully', {
                                                   position: "top-center",
                                                   autoClose: 5000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                });
                                             } else {
                                                toast.error('Process Failed', {
                                                   position: "top-center",
                                                   autoClose: 5000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                });
                                             }

                                          }, (error) => {
                                             console.log(error);
                                          }).finally(() => {
                                             setRegistarionButton({ val: "" })
                                          });

                                       // console.log(values);
                                    }}
                                 >
                                    {({ errors, touched, values }) => (
                                       <Form>
                                          <div className="row">
                                             <div className="col-sm-12">
                                                <div
                                                   style={{ height: '40vh', width: '100%' }}>

                                               
                                                   {(editGoogleLangititude && editGoogleLongtitude )&&
                                                   <GoogleMapReact
                                                      //   onClick={getlocalNameByCoord}
                                                      bootstrapURLKeys={{ key: 'AIzaSyD_ivQdtQK5UuTcspqtw5u5_r27Xbi_f9w' }}
                                                      defaultCenter={{
                                                         lat: parseFloat(editGoogleLangititude),
                                                         lng: parseFloat(editGoogleLongtitude)
                                                      }}
                                                      defaultZoom={zoom}
                                                      onDrag={(e) => {
                                                         setEditCurrentState({
                                                            langititude: e.center.lat(),
                                                            longtitude: e.center.lng()
                                                         })
                                                      }}
                                                      onDragEnd={(e) => {
                                                         getlocalNameByCoord(editCurrentState.langititude, editCurrentState.longtitude)
                                                      }}
                                                   >
                                                      <AnyReactComponent
                                                         lat={editCurrentState.langititude}
                                                         lng={editCurrentState.longtitude}
                                                         text="My Marker"
                                                      />
                                                   </GoogleMapReact>
                                                   }
                                                </div>
                                                <label className="control-label">Address <span className="required">*</span></label>
                                                <Field value={values.id} name='id' type="hidden" />
                                                <Field className="form-control border-form-control" value={values.address} placeholder="Address" name='address' type="hidden" />
                                                <Field className="form-control border-form-control" value={values.address} placeholder="Address" name='address' type="text" disabled />
                                                {errors.address && touched.address ? (<div style={{ color: 'red' }}>{errors.address}</div>) : null}
                                             </div>
                                          </div>

                                          <div className="row">
                                             <div className="col-sm-12">
                                                <div className="form-group">
                                                   <Field type="hidden" name="langititude" value={values.langititude} />
                                                   <Field type="hidden" name="longtitude" value={values.longtitude} />
                                                   <label className="control-label">Name <span className="required">*</span></label>
                                                   <Field className="form-control border-form-control" placeholder="Name" type="text" name="name" />
                                                   {errors.name && touched.name ? (<div style={{ color: 'red' }}>{errors.name}</div>) : null}
                                                </div>
                                             </div>
                                          </div>

                                          <div className="row">
                                             <div className="col-sm-12">
                                                <div className="form-group">
                                                   <label className="control-label">House Name/Number <span className="required">*</span></label>
                                                   <Field className="form-control border-form-control" name="place" placeholder="House Name/Number" type="text" />
                                                   {errors.place && touched.place ? (<div style={{ color: 'red' }}>{errors.place}</div>) : null}
                                                </div>
                                             </div>
                                          </div>


                                          <div className="row">
                                             <div className="col-sm-12">
                                                <div className="form-group">
                                                   <label className="control-label">Phone Number <span className="required">*</span></label>
                                                   <Field className="form-control border-form-control" placeholder="Phone Number" name="phonenumber" type="text" />
                                                   {errors.phonenumber && touched.phonenumber ? (<div style={{ color: 'red' }}>{errors.phonenumber}</div>) : null}
                                                </div>
                                             </div>
                                          </div>


                                          <div className="custom-control custom-checkbox mb-3">
                                             <Field type="checkbox" name='address_status' className="custom-control-input" id="customCheckbill"
                                             />
                                             <label className="custom-control-label" htmlFor="customCheckbill">Use my default address</label>
                                          </div>

                                          <div className="row">
                                             <div className="col-sm-12 text-right">
                                                <button type="button" className="btn btn-danger btn-lg" onClick={() => {
                                                   setEditTab('addresslist')
                                                }}> Cancel </button> &nbsp;
                                                <button type="submit" disabled={(registarionButton.val) ? "disabled" : ""} className="btn btn-success btn-lg"> Update Address </button>
                                             </div>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik>
                                 }
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>


               </div>
            </div>
         </section>



         <section className="section-padding bg-white border-top">
            <div className="container">
               <div className="row">
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-truck-fast"></i>
                        <h6>Free & Next Day Delivery</h6>
                        <p>Lorem ipsum dolor sit amet, cons...</p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-basket"></i>
                        <h6>100% Satisfaction Guarantee</h6>
                        <p>Rorem Ipsum Dolor sit amet, cons...</p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-tag-heart"></i>
                        <h6>Great Daily Deals Discount</h6>
                        <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <Footer />

      </React.Fragment>
   )
}
