
import React, { useState, useContext, useEffect } from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { apiRequestPOST } from '../utils/ApiCall';
import { BASE_URL } from '../utils/Constants';
import { toast } from 'react-toastify';
import { userDetailContext } from '../App'; 
import moment from 'moment'
import { useHistory } from "react-router-dom";
import get from 'lodash.get'

export default function Orderlist() {
    
   let history = useHistory();
   const [orderid, setOrderid] = useState([{}]);
   var contextData = useContext(userDetailContext);
   const [orderList, setOrderList] = useState([]);
   const [orderView, setOrderView] = useState([{}]);
   const location = useLocation();
  useEffect(() => {
      try {
         const orderlistid=JSON.parse(get(history,'location.state.id',true)); 
         if(orderlistid){  
            setOrderid(JSON.stringify(orderlistid)) 
            localStorage.setItem('orderlistid',JSON.stringify(orderlistid)); 
            // console.log('orderlistid',orderlistid);
         }
           }catch(err) {
              console.log('er')
           } 

            
      apiRequestPOST(`${BASE_URL}app_user_ctrl/get_orderview`, {id:JSON.parse(localStorage.getItem('orderlistid'))}, false)
         .then((response) => {
            setOrderList(response.orderlist)
            setOrderView(response.orderview)
         }, (error) => {
            console.log(error);
         });

      }, [location])


   return (
      <React.Fragment>
         <Header />

         <section className="account-page section-padding">
            <div className="container">
               <div className="row">
                  <div className="col-lg-9 mx-auto">
                     <div className="row no-gutters">
                        <div className="col-md-4">
                           <div className="card account-left">
                              <div className="user-profile-header">
                                 {/* <img alt="logo" src="assets/img/user.jpg"/> */}
                                 <h5 className="mb-1 text-secondary"><strong>Hi </strong> {contextData?.username?.name}</h5>
                                 <p> {contextData?.username?.mob}</p>
                              </div>
                              <div className="list-group">
                                 <Link to="/my_profile" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-account-outline"></i>  My Profile</Link>
                                 <Link to="/my_address" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-map-marker-circle"></i>  My Address</Link>
                                 <Link to="/wishlist" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-heart-outline"></i>  Wish List </Link>
                                 <Link to="/rating" className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-star-outline"></i>  Store Rating </Link>
                                 <Link to="/orderlist" className="list-group-item list-group-item-action active"><i aria-hidden="true" className="mdi mdi-format-list-bulleted"></i>  Order List</Link>
                                 <Link  className="list-group-item list-group-item-action"><i aria-hidden="true" className="mdi mdi-lock"></i>  Logout</Link>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-8">
                           <div className="card card-body account-right">
                              <div className="widget">
                                 <div className="section-header">
                                    <h5 className="heading-design-h5">
                                       Order List
                                    </h5>
                                 </div> 
                                 <left>Store Name: {orderList.store_name}</left>  <br/>
                                 <left>Branch: {orderList.branch_name} </left>
                                 
                                 <div className="order-list-tabel-main table-responsive">
                                            Order Status:{orderList.deliverystatus == 0 && <span className="badge badge-info">In Progress</span>}
                                                         {orderList.deliverystatus == 1 && <span className="badge badge-primary">Approved</span>}
                                                         {orderList.deliverystatus == 2 && <span className="badge badge-danger">Canceled</span>}
                                                         {orderList.deliverystatus == 3 && <span className="badge badge-warning">Assigned</span>}
                                                         {orderList.deliverystatus == 4 && <span className="badge badge-success">Delivered</span>}
                                    <table className="datatabel table table-striped table-bordered order-list-tabel" width="100%" cellspacing="0">
                                       <thead>

                                          <tr>
                                             <th>Product</th>
                                             <th>Quantity</th>
                                             <th>Rate</th>
                                             <th>Total</th> 
                                          </tr>
                                       </thead>
                                       <tbody>

                                          {
                                             orderView.length > 0 && orderView.map(item => {
                                                return (

                                                   <tr>
                                                      <td>{item.name}<br/>
                                                      {item.qty} &nbsp; {item.product_unit}</td> 
                                                      <td>{item.orderproductsqty}</td> 
                                                      <td>₹{item.rate}</td>
                                                      <td>₹{item.subtotal}</td> 
                                                   </tr>
                                                )
                                             })
                                          }
                                       </tbody>
                                    </table>

                                    <center > 
                                                        
                                                        
                                                         {orderList.deliverystatus == 0&&<><br />
                                    <button type="button" className="btn btn-danger btn-lg" 
                                      onClick={() => {
                                       apiRequestPOST(`${BASE_URL}app_user_ctrl/get_order_cancel`, { orderid: orderList.orderid }, false)
                                          .then((response) => {
                                             if (response.status == 1) {
                                                setOrderList(response.orderlist)
                                                setOrderView(response.orderview)
                                                toast.success('Order Cancel Successfully', {
                                                   position: "top-center",
                                                   autoClose: 5000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                });
                                             } else {
                                                toast.error('Process Failed', {
                                                   position: "top-center",
                                                   autoClose: 5000,
                                                   hideProgressBar: false,
                                                   closeOnClick: true,
                                                   pauseOnHover: true,
                                                   draggable: true,
                                                   progress: undefined,
                                                });
                                             }

                                          }, (error) => {
                                             console.log(error);
                                          }).finally(() => {
                                          });
                                    }}
                                    >Order Cancel </button>
                                    </>
                                                         }
                  &nbsp;<button type="button" className="btn btn-primary btn-lg"onClick={() =>
                                                      history.push({
                                                         pathname: "/orderlist" 
                                                      })
                                                   }>Back </button>
                                 </center>

                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="section-padding bg-white border-top">
            <div className="container">
               <div className="row">
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-truck-fast"></i>
                        <h6>Free & Next Day Delivery</h6>
                        <p>Lorem ipsum dolor sit amet, cons...</p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-basket"></i>
                        <h6>100% Satisfaction Guarantee</h6>
                        <p>Rorem Ipsum Dolor sit amet, cons...</p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-tag-heart"></i>
                        <h6>Great Daily Deals Discount</h6>
                        <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <Footer />

      </React.Fragment>
   )
}
