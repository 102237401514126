import React,{useState,useEffect,useContext,useRef} from 'react'
import { Link,useLocation } from 'react-router-dom' 
import Autocomplete from "react-google-autocomplete";
import { useHistory } from "react-router-dom";
import {apiRequestPOST} from '../utils/ApiCall'
import get from 'lodash.get' 
import { BASE_URL } from '../utils/Constants'; 
import { userDetailContext } from '../App';
import Header from './Header'
import Footer from './Footer' 
import { toast } from 'react-toastify'
export default function Index() { 
    
   
    const contextData = useContext(userDetailContext) 
   const [shopcategory, setShopCategory] = useState([]);
   let history = useHistory();

   const [shopproduct, setShopProduct] = useState([]);
   const [storearrayVal, setStorearrayVal] = useState([]);
   
   const location = useLocation();

  useEffect(async()=> {   

      apiRequestPOST(`${BASE_URL}app_ctrl/get_allcategory`,'', false)
      .then((response) => {
         setShopCategory(response) 
      }, (error) => {
         console.log(error);
      }); 

      const storearray = [] 
      await  contextData?.shoplist&&contextData?.shoplist.map(item => {
         storearray.push(item.id)
         })  
         setStorearrayVal(storearray) 
  },[location])

  
  useEffect(async()=>{ 
   await apiRequestPOST(`${BASE_URL}app_ctrl/get_allproduct`,{storearray:storearrayVal}, false)
   .then((response) => {
      setShopProduct(response) 
   }, (error) => {
      console.log(error);
   });  
      },[storearrayVal])

  const shoplistingItem = get(contextData,'shoplist',false) 

  const add_to_cart = (ProductId,store_id,branch_id) => { 
   const shopId =store_id; 
   const branchId =branch_id; 
     
   const userCartistbranch_id=JSON.parse(localStorage.getItem('userCartistbranch_id'));
   if(branchId==userCartistbranch_id || userCartistbranch_id==''){   
        apiRequestPOST(`${BASE_URL}app_user_ctrl/product_add_to_cart`,{product_id : ProductId,store_id:shopId,branch_id:branchId,qty:1},false)
      .then((response) => {
       
       apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`,'' ,false) 
       .then(async (response1) => { 
   await contextData.setUserCartist(response1);  
    localStorage.setItem('userCartist',JSON.stringify(response1.user_cart_list))    
    localStorage.setItem('userCartiststore_id', JSON.stringify(response1.store_id)) 
    localStorage.setItem('userCartistbranch_id', JSON.stringify(response1.branch_id)) 
       })
       .catch((error) => {  
        console.log(error); 
       });

        if(response == 0){  
          toast.error('Failed, Try again', {
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             });
      }
      if(response == 1){ 
          
          toast.success('Updated cart', {
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             });
      }
      if(response == 2){ 
          toast.error('Not enough stock !!!', {
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             });
      }
      if(response == 3){ 
          toast.error('Invalid Quantity !!!', {
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             });
      }
     }, (error) => {
        console.log(error);
      });
    }else{  
localStorage.setItem('diffrent_branch_userCartist',JSON.stringify({product_id : ProductId,store_id:shopId,branch_id:branchId,qty:1})) 
    document.getElementById('diffrent_branch').click()
 }
     }
    return (
       
      <React.Fragment>
      <Header />



      <section className="carousel-slider-main text-center border-top border-bottom bg-white">
         <div className="owl-carousel owl-carousel-slider">
       
            <div className="item">
               <img className="img-fluid" src={`${BASE_URL}assets/admin/resized/${contextData?.admindata?.slider_image1}`} alt="slider"/> 
                </div>
            <div className="item"> 
               <img className="img-fluid" src={`${BASE_URL}assets/admin/resized/${contextData?.admindata?.slider_image2}`} alt="slider"/> 
                </div>
            <div className="item"> 
               <img className="img-fluid" src={`${BASE_URL}assets/admin/resized/${contextData?.admindata?.slider_image3}`} alt="slider"/> 
                </div>
            
         </div>
      </section>
      

      <section className="top-category section-padding">
            <div className="container">
 

               <div className="owl-carousel owl-carousel-category"> 
                  {
                     shopcategory&&shopcategory.map(item => {
                        return (

                           <div className="item">
                              <div className="category-item">
                                 <Link>
                                 {item.category_image?
                                    <img className="img-fluid" src={`${BASE_URL}assets/category/original/${item.category_image}`} alt="" />
                                :<p data-letters={item.category_name.charAt(0)?.toUpperCase()}></p>
                                 }
                                    <h6>{item.category_name}</h6> 
                                 </Link>
                              </div>
                           </div>
                        )
                     })
                  } 
                </div> 

          
            </div>
         </section>
       
       
      <section className="product-items-slider section-padding">
         <div className="container">
            <div className="section-header">
               <h5 className="heading-design-h5">Our Stores <span className="badge badge-primary"></span>
                  <Link className="float-right text-secondary" to="/shop">View All</Link>
               </h5>
            </div> 
            <div className="owl-carousel owl-carousel-featured"> 
             
              {
                        contextData?.shoplist&&contextData?.shoplist.map(item => {
                           return (    
                                             <div className="item"  style={{ cursor: 'pointer' }} onClick={async  () =>  {
                        
                                                await localStorage.setItem('datahistory',JSON.stringify({ id: item.id,shop_datails:item })); 
                                                await contextData.setDataHistory(JSON.stringify({ id: item.id,shop_datails:item }));
                                                await contextData.setShopDatails(item);
                                                await contextData.setShopIdz(item.id);
                                                await contextData.setBranchId(item.branch_id);
                                                history.push({
                                              pathname: "/home", 
                                              state: { id: item.id,shop_datails:item }
                                           })
                                         }}>
                                                <div className="product">

                                                   <div className="product-header"> 
                                                      <img className="img-fluid" src={`${BASE_URL}assets/stores/original/${item.logo_image}`} alt="" />
                                                    </div>
                                                   <div className="product-body">


                                                   

                                                   <h5 title={item.store_name?.toUpperCase()}><center>
                                                      {item.store_name.length >= 17?
                                                     item.store_name?.toUpperCase().substring(0,17) + "..."
                                                  :item.store_name?.toUpperCase()}</center></h5>

                                                  <h6 title={item.branch_branch_name?.toUpperCase()}><center>
                                                      {item.branch_branch_name.length >= 20?
                                                     item.branch_branch_name?.toUpperCase().substring(0,20) + "..."
                                                  :item.branch_branch_name?.toUpperCase()}</center></h6>
                                                     </div>
                                                  

                                                   </div> 
                                                </div>  
                                   
                           )
                        })
                     }   
            </div>
               {
                       contextData?.shoplist?.length<=0&&
                       <center>  <img src="assets/img/empty_product.png" alt="" style={{width:'50%'}}/>
                       <br/><h4>Empty</h4></center>
                    }
         </div>
      </section>
      <section className="offer-product">
         <div className="container">
            <div className="row no-gutters">
               <div className="col-md-6">
                  <Link ><img className="img-fluid" src="assets/img/middle1.png" alt="" /></Link>
               </div>
               <div className="col-md-6">
                  <Link ><img className="img-fluid" src="assets/img/middle2.png" alt="" /></Link>
               </div>
            </div>
         </div>
      </section>


      <section className="product-items-slider section-padding">
         <div className="container">
            <div className="section-header">
               <h5 className="heading-design-h5">Best Products View  
                  {/* <Link className="float-right text-secondary" to="/">View All</Link> */}
               </h5>
            </div>
            <div className="owl-carousel owl-carousel-featured"> 
           
             
           {     
                       contextData?.shoplist&&shopproduct&&shopproduct?.map(subItm => {

                                         let original_price = subItm.original_price;
                                         let selling_price = subItm.selling_price;
                                         let discountamount = original_price - selling_price;
                                         let discount_percent = (discountamount / original_price) * 100;
                                         return (  
                                            <div className="item">
                                               <div className="product">

                                                  <div className="product-header"  >
                                                     {discount_percent > 0 ? (
                                                        <span className="badge badge-success">{(Math.round(discount_percent * 100) / 100).toFixed(0)}% OFF</span>
                                                     ) : (<></>)}
                                                  {subItm.pic1?<img className="img-fluid" src={`${BASE_URL}assets/products/original/${subItm.pic1}`} alt="" />
                                                    :<img className="img-fluid" src='http://via.placeholder.com/600x600&text=No Image' alt="" />}
                                                    {(localStorage.getItem("userToken") == null) ?
                                                    <span style={{ cursor: 'pointer' }} className=" text- mdi mdi-heart"  data-target="#bd-example-modal" data-toggle="modal"></span>
                                                     : 
                                                     contextData?.userfavourite.indexOf(subItm.id) > -1?
                                                        <span style={{ cursor: 'pointer' }} className=" text-danger mdi mdi-heart"
                                                     onClick={() => 
                                                       apiRequestPOST(`${BASE_URL}app_user_ctrl/add_favourite`,{ productId: subItm.id,status:0 }, false)
                                                            .then(async (response1) => {
                                                               response1!=2&&
                                                               await contextData.setUserFavourite(response1); 
                                                               localStorage.setItem('userFavourite', JSON.stringify(response1))   })
                                                           .catch((error) => {
                                                               console.log(error);
                                                            })
                                                 } ></span>
                                                 :
                                                 <span style={{ cursor: 'pointer' }} className=" text-  mdi mdi-heart"
                                                     onClick={() => 
                                                       apiRequestPOST(`${BASE_URL}app_user_ctrl/add_favourite`,{ productId: subItm.id,status:1 }, false)
                                                            .then(async (response1) => {
                                                               response1!=2&&
                                                               await contextData.setUserFavourite(response1); 
                                                               localStorage.setItem('userFavourite', JSON.stringify(response1))   })
                                                            .catch((error) => {
                                                               console.log(error);
                                                            })
                                                 } ></span>
                                                
                                                }

                                                  </div>
                                                  <div className="product-body" style={{ cursor: 'pointer' }} onClick={() =>
                                                     history.push({
                                                        pathname: "/single",
                                                        state: { productId: subItm.id }
                                                     })
                                                  }  >
 
                                                     <h5 title={subItm.name}>{
                                                     subItm.name.length >= 23?
                                                     subItm.name.substring(0,23) + "..."
                                                  :subItm.name
                                                  }</h5>



<h6><strong><span className="mdi mdi "></span>  </strong> {subItm.qty} {subItm.product_unit}
                                                      <span className="float-right"><strong><span className="mdi mdi "></span>  </strong> {subItm.brand_name}</span></h6>     </div>
                                                  <div className="product-footer">
                                                     
                                                  {(localStorage.getItem("userToken") == null) ?
                                                         <button type="button"  data-target="#bd-example-modal" data-toggle="modal" className="btn btn-secondary btn-sm float-right"><i className="mdi mdi-cart-outline"></i> Add To Cart</button>
                                                         : <button type="button" className="btn btn-secondary btn-sm float-right"
                                                            onClick={() => add_to_cart(subItm.id,subItm.store_id,subItm.branch_id)}
                                                         ><i className="mdi mdi-cart-outline"></i> Add To Cart</button>
                                                      }

                                                     <p className="offer-price mb-0">₹{subItm.selling_price} <i className="mdi mdi-tag-outline"></i><br /><span className="regular-price">₹{subItm.original_price}</span></p>
                                                  </div>

                                                  </div> 
                                               </div> 
                                         )
                                      }) 
                    }
                    
           </div>
                    {
                       contextData?.shoplist?.length<=0&&shopproduct?.length<=0&&
                       <center>  <img src="assets/img/empty_product.png" alt="" style={{width:'50%'}}/>
                       <br/><h4>Empty</h4></center>
                    }
                    
         </div>
      </section>



      <section className="section-padding bg-white border-top">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-truck-fast"></i>
                     <h6>Free & Next Day Delivery</h6>
                     <p>Lorem ipsum dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-basket"></i>
                     <h6>100% Satisfaction Guarantee</h6>
                     <p>Rorem Ipsum Dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-tag-heart"></i>
                     <h6>Great Daily Deals Discount</h6>
                     <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <Footer />

   </React.Fragment>
    )
}
