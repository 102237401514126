
import React, { useEffect, useState, useContext } from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import { useHistory } from "react-router-dom";
import get from 'lodash.get'
import { apiRequestPOST } from '../utils/ApiCall';
import { BASE_URL } from '../utils/Constants';
import { userDetailContext } from '../App';
import { toast } from 'react-toastify'; 
import moment from 'moment'
export default function Single() {
   var contextData = useContext(userDetailContext);
   let history = useHistory(); 
   const [productDetails, setproductDetails] = useState([]); 
   const [othreProductDetails, setotherProductDetails] = useState([]);

   const fetchProductDetails = (productIdval) => { 
    
      apiRequestPOST(`${BASE_URL}app_ctrl/get_product_details`, { productid: productIdval, store_id: contextData?.shopIdz, branch_id: contextData?.branchId }, false)
         .then((response) => {
            console.log(response);
            setproductDetails(response.product_details)
            setotherProductDetails(response.product_details_same_category_list)

         }, (error) => {
            console.log(error);
         });
   }
   const location = useLocation();
   useEffect(() => {
      // console.log('>> >111', get(history, 'location.state', false))
      try {
         const productId = get(history, 'location.state', false).productId
         if (productId) {
            localStorage.setItem('productId', productId)
         }
      } catch (err) {
         console.log('er')
      }
      const shopIdStringify = localStorage.getItem('datahistory')
       
 
      const productIdval = localStorage.getItem("productId")
      apiRequestPOST(`${BASE_URL}app_ctrl/get_product_details`, { productid: productIdval, store_id: contextData?.shopIdz, branch_id: contextData?.branchId }, false)
      .then((response) => {
         console.log(response);
         setproductDetails(response.product_details)
         setotherProductDetails(response.product_details_same_category_list)

      }, (error) => {
         console.log(error);
      });
      //   console.log(get(history,'location.state',''))

   }, [location])

   console.log('productDetails', productDetails);
   console.log('othreProductDetails', othreProductDetails);
   let original_price = productDetails?.original_price;
   let selling_price = productDetails?.selling_price;
   let discountamount = original_price - selling_price;
   let discount_percent = (discountamount / original_price) * 100;
   const add_to_cart = (ProductId) => {
      //   console.log(ProductId);
    

      const userCartistbranch_id = JSON.parse(localStorage.getItem('userCartistbranch_id'));
      if (contextData?.branchId == userCartistbranch_id || userCartistbranch_id=='') {
         apiRequestPOST(`${BASE_URL}app_user_ctrl/product_add_to_cart`, { product_id: ProductId, store_id: contextData?.shopIdz, branch_id: contextData?.branchId, qty: 1 }, false)
            .then((response) => {

               apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`, '', false)
                  .then(async (response1) => {
                     await contextData.setUserCartist(response1.user_cart_list);
                     localStorage.setItem('userCartist', JSON.stringify(response1.user_cart_list))
                     localStorage.setItem('userCartiststore_id', JSON.stringify(response1.store_id))
                     localStorage.setItem('userCartistbranch_id', JSON.stringify(response1.branch_id))
                  })
                  .catch((error) => {
                     console.log(error);
                  });

               if (response == 0) {
                  toast.error('Failed, Try again', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  });
               }
               if (response == 1) {

                  contextData.setUserCartist(response.name);   
                  toast.success('Updated cart', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     });
               }
               if (response == 2) {
                  toast.error('Not enough stock !!!', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  });
               }
               if (response == 3) {
                  toast.error('Invalid Quantity !!!', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                  });
               }
            }, (error) => {
               console.log(error);
            });
      } else {
         localStorage.setItem('diffrent_branch_userCartist', JSON.stringify({ product_id: ProductId, store_id: contextData?.shopIdz, branch_id: contextData?.branchId, qty: 1 }))
         document.getElementById('diffrent_branch').click()
      }
   }

   return (
      <React.Fragment>
         <Header />

         <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white">
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <Link ><strong><span className="mdi mdi-home"></span> Home</strong></Link> <span className="mdi mdi-chevron-right"></span> <Link >Fruits & Vegetables</Link> <span className="mdi mdi-chevron-right"></span> <Link >Fruits</Link>
                  </div>
               </div>
            </div>
         </section>
         <section className="shop-single section-padding pt-3">
            <div className="container">
               <div className="row">
                  <div className="col-md-6">
                     <div className="shop-detail-left">
                        <div className="shop-detail-slider">
                           <div className="favourite-icon">
                              <Link className="fav-btn" title="" data-placement="bottom" data-toggle="tooltip"  data-original-title={`${(Math.round(discount_percent * 100) / 100).toFixed(0)}% OFF`}><i className="mdi mdi-tag-outline"></i></Link>
                           </div>

                           <div id="sync1" className="owl-carousel">
                              <div className="item"><img alt="" src={`${BASE_URL}assets/products/original/${productDetails?.pic1}`} className="img-fluid img-center" /></div>
                              {(productDetails?.pic1 && productDetails?.pic2 != "") ? <div className="item"><img alt="" src={`${BASE_URL}assets/products/resized/${productDetails?.pic2}`} className="img-fluid img-center" /></div> : ''}
                              {(productDetails?.pic1 && productDetails?.pic3 != "") ? <div className="item"><img alt="" src={`${BASE_URL}assets/products/resized/${productDetails?.pic3}`} className="img-fluid img-center" /></div> : ''}
                           </div>
                           <div id="sync2" className="owl-carousel">
                              <div className="item"><img alt="" src={`${BASE_URL}assets/products/resized/${productDetails?.pic1}`} className="img-fluid img-center" /></div>
                              {(productDetails?.pic1 && productDetails?.pic2 != "") ? <div className="item"><img alt="" src={`${BASE_URL}assets/products/resized/${productDetails?.pic2}`} className="img-fluid img-center" /></div> : ''}
                              {(productDetails?.pic1 && productDetails?.pic3 != "") ? <div className="item"><img alt="" src={`${BASE_URL}assets/products/resized/${productDetails?.pic3}`} className="img-fluid img-center" /></div> : ''}
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-md-6">
                     <div className="shop-detail-right">
                        <span className="badge badge-success">{(Math.round(discount_percent * 100) / 100).toFixed(0)}% OFF</span>
                        <h5>{productDetails?.name}</h5>
                        <h6><strong><span className="mdi mdi-approval"></span> Available in</strong> -{productDetails?.qty} {productDetails?.product_unit}</h6>
                        <p className="regular-price"><i className="mdi mdi-tag-outline"></i> MRP : ₹{productDetails?.original_price}</p>
                        <p className="offer-price mb-0">Discounted price : <span className="text-success">₹{productDetails?.selling_price}</span></p>
                        {(localStorage.getItem("userToken") == null) ?
                           <button type="button" className="btn btn-secondary btn-lg" disabled={(productDetails?.product_stock > 0) ? '' : 'disable'}
                              data-target="#bd-example-modal" data-toggle="modal"><i className="mdi mdi-cart-outline"></i> Add To Cart</button>

                           : <button type="button" className="btn btn-secondary btn-lg" disabled={(productDetails?.product_stock > 0) ? '' : 'disable'}
                              onClick={() => add_to_cart(productDetails?.id)}  ><i className="mdi mdi-cart-outline"></i> Add To Cart</button>}
<p >Last Update : {moment(productDetails?.product_stock_updated_at).format('D-MMM-yyyy')}</p>
                        <div className="short-description">
                           <h5>
                              Quick Overview
                              <p className="float-right">Availability:&nbsp;
                                 {(productDetails?.product_stock > 0) ?
                                    <span className="badge badge-success">In Stock</span>
                                    : <span className="badge badge-danger" style={{ border: "1px solid #ff0018", color: "#ff0018" }}>Out Stock</span>}
                              </p>


                           </h5>
                           <p><b> </b>
                              {productDetails?.product_details}
                           </p>

                        </div>
                        {/* <h6 className="mb-3 mt-4">Why shop from Groci?</h6>
                     <div className="row">
                        <div className="col-md-6">
                           <div className="feature-box">
                              <i className="mdi mdi-truck-fast"></i>
                              <h6 className="text-info">Free Delivery</h6>
                              <p>Lorem ipsum dolor...</p>
                           </div>
                        </div>
                        <div className="col-md-6">
                           <div className="feature-box">
                              <i className="mdi mdi-basket"></i>
                              <h6 className="text-info">100% Guarantee</h6>
                              <p>Rorem Ipsum Dolor sit...</p>
                           </div>
                        </div>
                     </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="product-items-slider section-padding bg-white border-top">
            <div className="container">
               <div className="section-header">
                  <h5 className="heading-design-h5">Other Products
                     {/* <span className="badge badge-primary"> </span> */}
                     <Link className="float-right text-secondary" to="/product">View All</Link>
                  </h5>
               </div>
               <div className="owl-carousel owl-carousel-featured">


                  {
                     othreProductDetails && othreProductDetails.map(subItm => {

                        let original_price = subItm.original_price;
                        let selling_price = subItm.selling_price;
                        let discountamount = original_price - selling_price;
                        let discount_percent = (discountamount / original_price) * 100;
                        return (
                           <>
                              <div className="item">

                                 <div className="product" style={{ cursor: 'pointer' }}  onClick={() => fetchProductDetails(subItm.id)}  >

                                    <div className="product-header" style={{ cursor: 'pointer' }}>
                                       {discount_percent > 0 ? (
                                          <span className="badge badge-success">{(Math.round(discount_percent * 100) / 100).toFixed(0)}% OFF</span>
                                       ) : (<></>)}
                                       <img className="img-fluid" src={`${BASE_URL}assets/products/resized/${subItm.pic1}`} alt="" />
                                      
                                      
                                       {(localStorage.getItem("userToken") == null) ?
                                                    <span style={{ cursor: 'pointer' }} className=" text- mdi mdi-heart"  data-target="#bd-example-modal" data-toggle="modal"></span>
                                                     : 
                                                     contextData?.userfavourite.indexOf(subItm.id) > -1?
                                                        <span style={{ cursor: 'pointer' }} className=" text-danger mdi mdi-heart"
                                                     onClick={() => 
                                                       apiRequestPOST(`${BASE_URL}app_user_ctrl/add_favourite`,{ productId: subItm.id,branch_id:contextData?.branchId,status:0 }, false)
                                                            .then(async (response1) => {
                                                               response1!=2&&
                                                               await contextData.setUserFavourite(response1); 
                                                               localStorage.setItem('userFavourite', JSON.stringify(response1))   })
                                                           .catch((error) => {
                                                               console.log(error);
                                                            })
                                                 } ></span>
                                                 :
                                                 <span style={{ cursor: 'pointer' }} className=" text-  mdi mdi-heart"
                                                     onClick={() => 
                                                       apiRequestPOST(`${BASE_URL}app_user_ctrl/add_favourite`,{ productId: subItm.id,branch_id:contextData?.branchId,status:1 }, false)
                                                            .then(async (response1) => {
                                                               response1!=2&&
                                                               await contextData.setUserFavourite(response1); 
                                                               localStorage.setItem('userFavourite', JSON.stringify(response1))   })
                                                            .catch((error) => {
                                                               console.log(error);
                                                            })
                                                 } ></span>
                                                
                                                }
                                                
                                    </div>
                                    <div className="product-body">

                                       <h5  title={subItm.name}>{
                                          subItm.name.length >= 23?
                                          subItm.name.substring(0,23) + "..."
                                       :subItm.name
                                       }</h5>



<h6><strong><span className="mdi mdi "></span>  </strong> {subItm.qty} {subItm.product_unit}
                                                      <span className="float-right"><strong><span className="mdi mdi "></span>  </strong> {subItm.brand_name}</span></h6>      </div>
                                    <div className="product-footer">
                                       {(localStorage.getItem("userToken") == null) ?
                                          <button type="button"  data-target="#bd-example-modal" data-toggle="modal" className="btn btn-secondary btn-sm float-right"><i className="mdi mdi-cart-outline"></i> Add To Cart</button>
                                          : <button type="button" className="btn btn-secondary btn-sm float-right"
                                             onClick={() => add_to_cart(subItm.id)}
                                          ><i className="mdi mdi-cart-outline"></i> Add To Cart</button>
                                       }
                                       <p className="offer-price mb-0">₹{subItm.selling_price} <i className="mdi mdi-tag-outline"></i><br /><span className="regular-price">₹{subItm.original_price}</span></p>
                                    </div>

                                 </div>

                              </div>

                           </>

                        )
                     })
                  }


               </div>
            </div>
         </section>
         <section className="section-padding bg-white border-top">
            <div className="container">
               <div className="row">
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-truck-fast"></i>
                        <h6>Free & Next Day Delivery</h6>
                        <p>Lorem ipsum dolor sit amet, cons...</p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-basket"></i>
                        <h6>100% Satisfaction Guarantee</h6>
                        <p>Rorem Ipsum Dolor sit amet, cons...</p>
                     </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                     <div className="feature-box">
                        <i className="mdi mdi-tag-heart"></i>
                        <h6>Great Daily Deals Discount</h6>
                        <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>


         <Footer />

      </React.Fragment>
   )
}
