import React,{ useEffect, useState,useContext  } from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import Autocomplete from "react-google-autocomplete";
import { useHistory } from "react-router-dom";
import {apiRequest, apiRequestPOST} from '../utils/ApiCall'
import get from 'lodash.get'
import axios from 'axios';
import { BASE_URL, POST_HEADER } from '../utils/Constants';
import { userDetailContext } from '../App';
export default function Index() {

   var contextData = useContext(userDetailContext);
   const [tab,setTab] = useState('login') 
   let history = useHistory(); 

   const location = useLocation();
   useEffect(()=> {  
  
let lat= get(history,'location.state.lat',false);
let lng= get(history,'location.state.lng',false);
if(lat==false){
     lat= localStorage.getItem("geoLat")
     lng= localStorage.getItem("geoLng")
}
try {
apiRequestPOST(`${BASE_URL}app_ctrl/get_geoCoordinate`,{ log: lng,lng:lat }, false)
.then((response) => {
   console.log('response',response);
   localStorage.setItem('shoplisttemphistory',JSON.stringify(response.query));  
   contextData.setShopList(response.query)                 
}, (error) => {
   console.log(error);
}) 
} catch (err) {
   console.log('er')
}

},[location])
 const shoplistingItem = get(contextData,'shoplist',false)
    return (
       
        <React.Fragment>
          
       
<Header/>
    

       



      <section className="carousel-slider-main text-center border-top border-bottom bg-white">
         <div className="owl-carousel owl-carousel-slider">
       
            <div className="item">
               <img className="img-fluid" src={`${BASE_URL}assets/admin/resized/${contextData?.admindata?.slider_image1}`} alt="slider"/> 
                </div>
            <div className="item"> 
               <img className="img-fluid" src={`${BASE_URL}assets/admin/resized/${contextData?.admindata?.slider_image2}`} alt="slider"/> 
                </div>
            <div className="item"> 
               <img className="img-fluid" src={`${BASE_URL}assets/admin/resized/${contextData?.admindata?.slider_image3}`} alt="slider"/> 
                </div>
               
            
         </div>
      </section>
      
     
   
     <section className="section-padding bg-white">
        <div className="container">
           <div className="row"> 
              <div className="col-md-12">
           <div className="row no-gutters">
 
           { 
           shoplistingItem&&shoplistingItem.map( item => { 
                        return ( 
                     <div className="col-md-4"   style={{cursor:'pointer',paddingLeft:10}}   onClick={async  () =>  {
                        
                            await localStorage.setItem('datahistory',JSON.stringify({ id: item.id,shop_datails:item })); 
                            await contextData.setDataHistory(JSON.stringify({ id: item.id,shop_datails:item }));
                            await contextData.setShopDatails(item);
                            await contextData.setShopIdz(item.id);
                            await contextData.setBranchId(item.branch_id);
                             history.push({
                          pathname: "/home", 
                          state: { id: item.id,shop_datails:item }
                       })
                     }
                       }  >  
                       {item.store_name&&
                        <div className="product">
                          
                              <div className="product-header">
                                 
                                 <img className="img-fluid" src={`${BASE_URL}assets/stores/original/${item.logo_image}`} alt="Vendor" />
                              
                              </div>
                              <div className="product-body">
                                 <h4><center>{item.store_name?.toUpperCase()}</center></h4>
                                 <h6><center>{item.branch_branch_name?.toUpperCase()}</center></h6>
                              </div> 
                          
                        </div> 
           }
                     </div>   
                     )
                  }
                  )

               }
            
{get(contextData,'shoplist[0].id',0)>0?null:
      <div className="col-md-12">
         <center><h2><b>Empty !</b></h2></center>
</div>
}
                    
                  </div>
                  </div>

           </div>
        </div>
     </section>

  
     <Footer/>
     </React.Fragment>
    )
}
