
import React,{ useEffect, useState,useContext} from 'react'
import { Link,useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import get from 'lodash.get'
import { useHistory } from "react-router-dom";  
import { BASE_URL } from '../utils/Constants'
import { apiRequestPOST } from '../utils/ApiCall' 
import { toast } from 'react-toastify'
import { userDetailContext } from '../App';
import { array } from 'yup/lib/locale'

export default function Products() {
   var contextData = useContext(userDetailContext);
   let history = useHistory();
   const [shopproduct, setShopProduct] = useState([]);
   const [shopCategory, setShopCategory] = useState([]);  
   const [allBrand, setAllBrand] = useState([]); 
   const [allBrandFilter, setAllBrandFilter] = useState(''); 
   const [isCheckedBrand, setIsCheckedBrand] = useState([]);
   const [isCheckedSubcategory, setIsCheckedSubcategory] = useState([]);
   const [sortOrder, setSortOrder] = useState([]);
   const [priceSort, setPriceSort] = useState(2000); 

   const location = useLocation();
  useEffect(()=> {

      try {
      const data = get(history,'location.state',true)   
      console.log('data',data);   
      if(data){
      setShopCategory(data) 
      localStorage.setItem('searchProductName',JSON.stringify(data))    
   }
   }catch(err) {
      console.log('er')
   }  
const searchProductName= JSON.parse(localStorage.getItem('searchProductName'))  
 
apiRequestPOST(`${BASE_URL}app_ctrl/get_search_product`,{search : searchProductName.search,log:JSON.parse(localStorage.getItem("geoLng")),lng:JSON.parse(localStorage.getItem("geoLat")), price: priceSort,order:sortOrder, brand: isCheckedBrand},false)
.then(async(response) => {  
                          await setShopProduct(response)   
                         }, (error) => {
                           console.log(error);
                         }); 
                    
                         apiRequestPOST(`${BASE_URL}app_ctrl/get_allbrand`,'', false)
                         .then(async (response) => {     
                            await setAllBrand(response) 
                         }, (error) => {       
                            console.log(error);
                         }) 
         },[location]) 
         
         const add_to_cart = (ProductId,store_id,branch_id) => { 
        const shopId =store_id; 
        const branchId =branch_id; 
          
        const userCartistbranch_id=JSON.parse(localStorage.getItem('userCartistbranch_id'));
        if(branchId==userCartistbranch_id || userCartistbranch_id==''){   
             apiRequestPOST(`${BASE_URL}app_user_ctrl/product_add_to_cart`,{product_id : ProductId,store_id:shopId,branch_id:branchId,qty:1},false)
           .then((response) => {
            
            apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`,'' ,false) 
            .then(async (response1) => { 
        await contextData.setUserCartist(response1);  
         localStorage.setItem('userCartist',JSON.stringify(response1.user_cart_list))    
         localStorage.setItem('userCartiststore_id', JSON.stringify(response1.store_id)) 
         localStorage.setItem('userCartistbranch_id', JSON.stringify(response1.branch_id)) 
            })
            .catch((error) => {  
             console.log(error); 
            });

             if(response == 0){  
               toast.error('Failed, Try again', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
           }
           if(response == 1){ 
               
               toast.success('Updated cart', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
           }
           if(response == 2){ 
               toast.error('Not enough stock !!!', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
           }
           if(response == 3){ 
               toast.error('Invalid Quantity !!!', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
           }
          }, (error) => {
             console.log(error);
           });
         }else{  
   localStorage.setItem('diffrent_branch_userCartist',JSON.stringify({product_id : ProductId,store_id:shopId,branch_id:branchId,qty:1})) 
         document.getElementById('diffrent_branch').click()
      }
          }
       

          const get_shopcategoryproduct = (order) => {  
            setSortOrder(order)
            apiRequestPOST(`${BASE_URL}app_ctrl/get_search_product`,{search : JSON.parse(localStorage.getItem('searchProductName')).search,log:JSON.parse(localStorage.getItem("geoLng")),lng:JSON.parse(localStorage.getItem("geoLat")), price: priceSort,order:order, brand: isCheckedBrand},false)
          .then((response) => {
                                    console.log('response',response);
                                    setShopProduct(response)
                                 }, (error) => {
                                    console.log(error);
                                 })
                              }
      
                         
                              const get_shopcategoryproductprice = (price) => { 
                                 setPriceSort(price)
                                apiRequestPOST(`${BASE_URL}app_ctrl/get_search_product`,{search : JSON.parse(localStorage.getItem('searchProductName')).search,log:JSON.parse(localStorage.getItem("geoLng")),lng:JSON.parse(localStorage.getItem("geoLat")), price: price,order:sortOrder, brand: isCheckedBrand},false)
                              .then((response) => {
                                                         console.log('response',response);
                                                         setShopProduct(response)
                                                      }, (error) => {
                                                         console.log(error);
                                                      })
                                                   }

                                                                 
            const brandShortlistedApplicant = (e, priceId) => {  
      const checked = e.target.checked;
      const priceCheckbox = isCheckedBrand 
      if (checked) {
         !priceCheckbox.includes(priceId) && priceCheckbox.push(priceId)

      } else {
         const index = priceCheckbox.indexOf(priceId);
         if (index > -1) {
            priceCheckbox.splice(index, 1)
         }
      }  

        apiRequestPOST(`${BASE_URL}app_ctrl/get_search_product`,{search : JSON.parse(localStorage.getItem('searchProductName')).search,log:JSON.parse(localStorage.getItem("geoLng")),lng:JSON.parse(localStorage.getItem("geoLat")), price: priceSort,order:sortOrder, brand: priceCheckbox},false)
      .then((response) => {
            console.log(response);
            setShopProduct(response)
         }, (error) => {
            console.log(error);
         });


      setIsCheckedBrand(priceCheckbox)
   }
  
          const funallBrandFilter=(searchStr)=>{
            setAllBrandFilter(searchStr)
         } 

         const allRanges = document.querySelectorAll(".range-wrap");
         allRanges.forEach(wrap => {
           const range = wrap.querySelector(".range");
           const bubble = wrap.querySelector(".bubble");
         
           range.addEventListener("input", () => {
             setBubble(range, bubble);
           });
           setBubble(range, bubble);
         });
         
         function setBubble(range, bubble) {
           const val = range.value;
           const min = range.min ? range.min : 0;
           const max = range.max ? range.max : 100;
           const newVal = Number(((val - min) * 100) / (max - min));
           bubble.innerHTML = val;
         
           // Sorta magic numbers based on size of the native UI thumb
           bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
         }
    return (
     <React.Fragment>  
     <Header/>
 
     
     <section className="shop-list section-padding">
            <div className="container">
               <div className="row">
                  <div className="col-md-3">
                     <div className="shop-filters">
                        <div id="accordion">
                          

                           <div className="card">
                              <div className="card-header" id="headingTwo">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                       Price <span className="mdi mdi-chevron-down float-right"></span>
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                                 <div className="card-body card-shop-filters">

                                 <div className="range-wrap">
  <input type="range" className="range" min="0" max="2000" value={priceSort} onChange={(e) => get_shopcategoryproductprice(e.target.value)}/>
  <output className="bubble"></output>
</div>

                             
 
                                 </div>
                              </div>
                           </div>

                           <div className="card">
                              <div className="card-header" id="headingThree">
                                 <h5 className="mb-0">
                                    <button className="btn btn-link  " data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseTwo">
                                       Brand <span className="mdi mdi-chevron-down float-right"></span>
                                    </button>
                                 </h5>
                              </div>
                              <div id="collapseThree" className="collapse show" aria-labelledby="headingThree" data-parent="#accordion">
                                 <div className="card-body card-shop-filters">
                              
                                

<div className="input-group">
<input type="text" className="form-control" placeholder="Search By Brand" onKeyUp={(e) => {funallBrandFilter(e.target.value)}}/>
<span style={{marginLeft:'-8%',marginTop:'4px',zIndex:10}}><i className="fa fa-search"></i></span>
  </div>
                                 
                                 {
                     allBrand&&allBrand.map(item => {
                        if(item.brand?.toUpperCase().indexOf(allBrandFilter.toUpperCase()) > -1) {

                        return ( 
                                    <div className="custom-control custom-checkbox">
                                       <input type="checkbox" checked={isCheckedBrand.includes(item.id)} onClick={(e) => {
                                          brandShortlistedApplicant(e, item.id);}
                                       } className="custom-control-input" id={item.brand} />
                                       <label className="custom-control-label" for={item.brand}>{item.brand}
                                       </label>
                                    </div> 
                                    
                        )
                   }
                     })
                        }
                                  
                                

                                 </div>
                              </div>
                           </div>
                           
                        </div>
                     </div>
                     <div className="left-ad mt-4">
					  <img className="img-fluid" src="assets/img/left.jpg" alt=""/> 
					  <img className="img-fluid" src="assets/img/left1.gif" alt="" style={{marginTop:'62px'}}/> 
				   </div>
                  </div>
                  <div className="col-md-9" >
                    
               
                     <div className="shop-head">
                     <Link ><span className="mdi mdi-home"></span> Search :{JSON.parse(localStorage.getItem('searchProductName'))?.search}</Link>  
                   
                     {  shopproduct.length!=0&&   
                       <div className="btn-group float-right mt-2">
                        <button type="button" className="btn btn-dark dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Sort by Products &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                           {/* <Link className="dropdown-item" >Relevance</Link> */}
                           <Link className="dropdown-item"   onClick={() => get_shopcategoryproduct('LH')} >Price (Low to High)</Link>
                           <Link className="dropdown-item"   onClick={() => get_shopcategoryproduct('HL')}>Price (High to Low)</Link>
                           {/* <Link className="dropdown-item" >Discount (High to Low)</Link> */}
                           <Link className="dropdown-item"   onClick={() => get_shopcategoryproduct('AZ')}>Name (A to Z)</Link>
                           <Link className="dropdown-item"   onClick={() => get_shopcategoryproduct('ZA')}>Name (Z to A)</Link>
                        </div>
                     </div>}
                   
                  </div>
                  <br/>
                  
                    
                              <div>
                                       <div className="row no-gutters">
                                    
                                    {
                                       shopproduct.length>0 && shopproduct.map(subItm => {

                                          let original_price = subItm.original_price;
                                          let selling_price = subItm.selling_price;
                                          let discountamount = original_price - selling_price;
                                          let discount_percent = (discountamount / original_price) * 100;
                                          return ( 
                                             <div className="col-md-4">
                                                {/* {JSON.stringify(subItm)} */} 
                                                <div className="product">

                                                   <div className="product-header">
                                                      {discount_percent > 0 ? (
                                                         <span className="badge badge-success">{(Math.round(discount_percent * 100) / 100).toFixed(0)}% OFF</span>
                                                      ) : (<></>)}
                                                    {subItm.pic1?<img className="img-fluid" src={`${BASE_URL}assets/products/original/${subItm.pic1}`} alt="" />
                                                    :<img className="img-fluid" src='http://via.placeholder.com/600x600&text=No Image' alt="" />}
                                                    {(localStorage.getItem("userToken") == null) ?
                                                    <span style={{ cursor: 'pointer' }} className=" text- mdi mdi-heart"  data-target="#bd-example-modal" data-toggle="modal"></span>
                                                     : 
                                                     contextData?.userfavourite.indexOf(subItm.id) > -1?
                                                        <span style={{ cursor: 'pointer' }} className=" text-danger mdi mdi-heart"
                                                     onClick={() => 
                                                       apiRequestPOST(`${BASE_URL}app_user_ctrl/add_favourite`,{ productId: subItm.id,branch_id:subItm.branch_id,status:0 }, false)
                                                            .then(async (response1) => {
                                                               response1!=2&&
                                                               await contextData.setUserFavourite(response1); 
                                                               localStorage.setItem('userFavourite', JSON.stringify(response1))   })
                                                           .catch((error) => {
                                                               console.log(error);
                                                            })
                                                 } ></span>
                                                 :
                                                 <span style={{ cursor: 'pointer' }} className=" text-  mdi mdi-heart"
                                                     onClick={() => 
                                                       apiRequestPOST(`${BASE_URL}app_user_ctrl/add_favourite`,{ productId: subItm.id,branch_id:subItm.branch_id,status:1 }, false)
                                                            .then(async (response1) => {
                                                               response1!=2&&
                                                               await contextData.setUserFavourite(response1); 
                                                               localStorage.setItem('userFavourite', JSON.stringify(response1))   })
                                                            .catch((error) => {
                                                               console.log(error);
                                                            })
                                                 } ></span>
                                                
                                                }

                                                   </div>
                                                   <div className="product-body" style={{ cursor: 'pointer' }} onClick={() =>
                                                      history.push({
                                                         pathname: "/single",
                                                         state: { productId: subItm.id }
                                                      })
                                                   }  >

<h5 title={subItm.name}>{
                                                     subItm.name.length >= 23?
                                                     subItm.name.substring(0,23) + "..."
                                                  :subItm.name
                                                  }</h5>



<h6><strong><span className="mdi mdi "></span>  </strong> {subItm.qty} {subItm.product_unit}
                                                      <span className="float-right"><strong><span className="mdi mdi "></span>  </strong> {subItm.brand_name}</span></h6>            </div>
                                                   <div className="product-footer">
                                                      {(localStorage.getItem("userToken") == null) ?
                                                         <button type="button"  data-target="#bd-example-modal" data-toggle="modal" className="btn btn-secondary btn-sm float-right"><i className="mdi mdi-cart-outline"></i> Add To Cart</button>
                                                         : <button type="button" className="btn btn-secondary btn-sm float-right"
                                                            onClick={() => add_to_cart(subItm.id,subItm.store_id,subItm.branch_id)}
                                                         ><i className="mdi mdi-cart-outline"></i> Add To Cart</button>
                                                      }
                                                      <p className="offer-price mb-0">₹{subItm.selling_price} <i className="mdi mdi-tag-outline"></i><br /><span className="regular-price">₹{subItm.original_price}</span></p>
                                                   </div>

                                                </div> 
                                             </div>
                                          )
                                       })
                                    }
                                    
                                 </div>
                                 {
   shopproduct.length==0&&
   <div className="row no-gutters" style={{marginTop:'22px'}}>
       <div className="col-md-12">
          <center>
          <img src="assets/img/empty_product.png" alt="" style={{width:'80%'}}/>
          <br/><h2>Empty Product</h2>
          </center>
          </div></div>
}
                              </div>  
                  </div>
               </div>
            </div>
         </section>

  
    
      <section className="section-padding bg-white border-top">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-truck-fast"></i>
                     <h6>Free & Next Day Delivery</h6>
                     <p>Lorem ipsum dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-basket"></i>
                     <h6>100% Satisfaction Guarantee</h6>
                     <p>Rorem Ipsum Dolor sit amet, cons...</p>
                  </div>
               </div>
               <div className="col-lg-4 col-sm-6">
                  <div className="feature-box">
                     <i className="mdi mdi-tag-heart"></i>
                     <h6>Great Daily Deals Discount</h6>
                     <p>Sorem Ipsum Dolor sit amet, Cons...</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
     
      
   <Footer/>
     
     </React.Fragment>
    )
}

