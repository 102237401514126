 
	 import React,{ useEffect,useRef, useState,useContext  } from 'react'
    import { Link,useLocation } from 'react-router-dom'
    import Header from './Header'
    import Footer from './Footer'
    import { apiRequestPOST } from '../utils/ApiCall';
    import { BASE_URL } from '../utils/Constants';
    import { useHistory } from "react-router-dom"; 
    import { toast } from 'react-toastify'
    import { userDetailContext } from '../App'; 
    
    export default function Product() {
      const inputEl = useRef(null);

      var contextData = useContext(userDetailContext);
      let history = useHistory();
      const [inputValue,setInputValue] = useState([])
      const [suggestionValue,setSuggestionValue] = useState([])
      const [suggestionStore,setSuggestionStore] = useState([])
      const [suggestionProduct,setSuggestionProduct] = useState([])
      const [suggestionSearch,setSuggestionSearch ] = useState([]) 
      const add_to_cart = (ProductId) => {
         //   console.log(ProductId);
        const shopIdStringify=localStorage.getItem('datahistory')
        const shopId =contextData?.shopIdz;
        const branchId =contextData?.branchId;  
        
        const userCartistbranch_id=JSON.parse(localStorage.getItem('userCartistbranch_id'));
         if(branchId==userCartistbranch_id || userCartistbranch_id==''){   
           apiRequestPOST(`${BASE_URL}app_user_ctrl/product_add_to_cart`,{product_id : ProductId,store_id:shopId,branch_id:branchId,qty:1},false)
           .then((response) => {
            
            apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`,'' ,false) 
            .then(async (response1) => { 
        await contextData.setUserCartist(response1.user_cart_list);  
         localStorage.setItem('userCartist',JSON.stringify(response1.user_cart_list))      
         localStorage.setItem('userCartiststore_id', JSON.stringify(response1.store_id))
         localStorage.setItem('userCartistbranch_id', JSON.stringify(response1.branch_id))
            })
            .catch((error) => {  
             console.log(error); 
            });

             if(response == 0){  
               toast.error('Failed, Try again', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
           }
           if(response == 1){ 
               
               toast.success('Updated cart', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
           }
           if(response == 2){ 
               toast.error('Not enough stock !!!', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
           }
           if(response == 3){ 
               toast.error('Invalid Quantity !!!', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
           }
          }, (error) => {
             console.log(error);
           });
         }else{   
   localStorage.setItem('diffrent_branch_userCartist',JSON.stringify({product_id : ProductId,store_id:shopId,branch_id:branchId,qty:1})) 
         document.getElementById('diffrent_branch').click()
      }
          }
          const suggestionProduct_add_to_cart = (ProductId,store_id,branch_id) => {
            //   console.log(ProductId); 
              
           const userCartistbranch_id=JSON.parse(localStorage.getItem('userCartistbranch_id'));
            if(branch_id==userCartistbranch_id || userCartistbranch_id==''){   
              apiRequestPOST(`${BASE_URL}app_user_ctrl/product_add_to_cart`,{product_id : ProductId,store_id:store_id,branch_id:branch_id,qty:1},false)
              .then((response) => {
               
               apiRequestPOST(`${BASE_URL}app_ctrl/datahistory`, {product_id : ProductId,store_id:store_id,branch_id:branch_id,qty:1}, false)
               .then(async (response2) => { 
                  await localStorage.setItem('datahistory',JSON.stringify(response2));
                  await contextData.setDataHistory(JSON.stringify(response2));
                  await contextData.setShopDatails(response2.shop_datails);
                  await contextData.setShopIdz(response2.id);
                  await contextData.setBranchId(response2.shop_datails.branch_id);
                 })
               .catch((error) => {
                  console.log(error);
               });

               apiRequestPOST(`${BASE_URL}app_user_ctrl/user_cart_list`,'' ,false) 
               .then(async (response1) => { 
           await contextData.setUserCartist(response1.user_cart_list);  
            localStorage.setItem('userCartist',JSON.stringify(response1.user_cart_list))      
            localStorage.setItem('userCartiststore_id', JSON.stringify(response1.store_id))
            localStorage.setItem('userCartistbranch_id', JSON.stringify(response1.branch_id))
               })
               .catch((error) => {  
                console.log(error); 
               });
   
                if(response == 0){  
                  toast.error('Failed, Try again', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     });
              }
              if(response == 1){ 
                  
                  toast.success('Updated cart', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     });
              }
              if(response == 2){ 
                  toast.error('Not enough stock !!!', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     });
              }
              if(response == 3){ 
                  toast.error('Invalid Quantity !!!', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     });
              }
             }, (error) => {
                console.log(error);
              });
            }else{   
      localStorage.setItem('diffrent_branch_userCartist',JSON.stringify({product_id : ProductId,store_id:store_id,branch_id:branch_id,qty:1})) 
            document.getElementById('diffrent_branch').click()
         }
             }
        return (
         <React.Fragment> 
         <Header/>
         
        <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <Link ><strong><span className="mdi mdi-home"></span> Home</strong></Link> <span className="mdi mdi-chevron-right"></span> <Link >Shop</Link>
               </div>
            </div>
         </div>
      </section>


      <section className="shop-list section-padding" style={{minHeight:'500px'}}>
      <div className="container">
            <div className="row">
               <div className="col-md-12"> 
               <div className="row">  
                <div className="col-md-11">
               <input  style={{height:'55px',fontSize:'22px',fontWeight:400}}
               // ref={inputEl}
               className="form-control" id='search_id' autoFocus  placeholder="Search products in Your City" aria-label="Search Store Or Products" type="text"
               onChange={e => {
                  console.log('>>htting',e.target.value) 
                  setInputValue(e.target.value)   
                  e.target.value.length==0&&setSuggestionValue([])
                   apiRequestPOST(`${BASE_URL}app_ctrl/get_search`,{search : e.target.value,log:JSON.parse(localStorage.getItem("geoLng")),lng:JSON.parse(localStorage.getItem("geoLat"))},false)
                   .then((response) => { 
                     setSuggestionValue(response)  
                     setSuggestionStore([])      
                     setSuggestionProduct([])  
                   }, (error) => {
                     console.log(error);
                   })
                   
                }} 
               /></div><div className="col-md-1"><span  style={{fontSize:'27px',cursor:'pointer'}} onClick={()=>
                      {inputEl.current.value = ''
                        setSuggestionValue([]) 
                        inputEl.current.focus()
                        
                        history.push({
                           pathname: "/home", 
                             })
                     }
                      
               }><b> &#x2715; </b>  </span></div></div>
                    {suggestionValue.length>=0&&        
                     <div className="row">  
                     <div className="col-md-11">
                  <div className="card card-body cart-table" style={{maxHeight:'340px'}}>
                     <div className="table-responsive">
                        <table className="table cart_summary"> 
                           <tbody>
                           { 
           suggestionValue.length > 0 && suggestionValue.map(item => { 
                        return (    
                              <tr style={{cursor:'pointer'}} 
                              onClick={()=> {
                    
                                 if(item.productname=='') {
                                 
                                    apiRequestPOST(`${BASE_URL}app_ctrl/get_search_store`,{search : item.id,log:JSON.parse(localStorage.getItem("geoLng")),lng:JSON.parse(localStorage.getItem("geoLat"))},false)
                                     .then((response) => {
                                       console.log("ewr1",response); 
                                       setInputValue([])
                                       setSuggestionStore(response)    
                                       setSuggestionSearch(item.store_name)  
                                      setSuggestionValue([])   
                                      inputEl.current.value = ''
                                    }, (error) => {
                                      console.log(error);
                                    })
                                 } 
                                 else{
                                    apiRequestPOST(`${BASE_URL}app_ctrl/get_search_product`,{search : item.productname,log:JSON.parse(localStorage.getItem("geoLng")),lng:JSON.parse(localStorage.getItem("geoLat"))},false)
                                     .then((response) => {
                                       console.log("ewr2",response); 
                                       setInputValue([])
                                       setSuggestionProduct(response)     
                                       setSuggestionSearch(item.productname)    
                                      setSuggestionValue([])   
                                      document.getElementById('search_id').value('')
                                    }, (error) => {
                                      console.log(error);
                                    })
                              }
                              
                           }}>
                                 <td className="cart_product"> <img className="img-fluid" src={`${BASE_URL}assets/stores/original/${item.logo_image}`} alt="Vendor"/></td>
                                 <td className="cart_description">
                                    <h5 className="product-name">{item.productname==''?item.store_name:item.productname}</h5> 
                                    <h6><strong><span className="mdi mdi-approval"></span> {item.productname==''?item.branch_branch_name+' Branch':'Product'}</strong></h6>
                                </td> 
                              </tr> 
                                 )
                              }
                              )
            
                           }

{ 
           suggestionValue.length == 0 &&inputValue.length>0&&   
                              <tr>
                                 <td colSpan={2}> <center>No Result Found</center></td>
                              
                              </tr>  
                           }
                           </tbody>
                          
                        </table>
                        <br/>
                        {/* <h2>Recent Searches</h2> */}
                     </div>
                     </div>
                     </div></div>
                    }
           {(suggestionStore.length>0) && 

           <><br/><center><h2>{suggestionSearch}</h2></center>
                    <div className="row">
                    { 
           suggestionStore&&suggestionStore.map(subItm => { 

                  let original_price = subItm.original_price;
                  let selling_price =subItm.selling_price ;
                  let discountamount = original_price-selling_price; 
                  let discount_percent = (discountamount/original_price)*100;  
                        return ( 
                           
                     <div className="col-md-3">
                                             {/* {JSON.stringify(subItm)} */}

                            
                        <div className="product">
                          
                              <div className="product-header" style={{cursor:'pointer'}}  onClick={() => 
                         history.push({
                           pathname: "/single", 
                              state:{productId: subItm.id} 
                        })
                        }  >
                              {discount_percent>0 ? (  
                                 <span className="badge badge-success">{(Math.round(discount_percent * 100) / 100).toFixed(0)}% OFF</span>
                                 ):(<></>)}
                                 <img className="img-fluid" src={`${BASE_URL}assets/products/resized/${subItm.pic1}`} alt=""/>
                                 {/* <span className="veg text-success mdi mdi-circle"></span> */}
                              </div>
                              <div className="product-body" style={{cursor:'pointer'}}   onClick={() => 
                         history.push({
                           pathname: "/single", 
                              state:{productId: subItm.id} 
                        })
                        }  >
                                 
                                 <h5 title={subItm.name}>{
                                                     subItm.name.length >= 23?
                                                     subItm.name.substring(0,23) + "..."
                                                  :subItm.name
                                                  }</h5>
                                 
 

                                 <h6><strong><span className="mdi mdi "></span>  </strong> {subItm.qty} {subItm.product_unit}
                                                      <span className="float-right"><strong><span className="mdi mdi "></span>  </strong> {subItm.brand_name}</span></h6> </div>
                              <div className="product-footer">
                              {(localStorage.getItem("userToken") == null) ?
                                 <button type="button"  data-target="#bd-example-modal" data-toggle="modal" className="btn btn-secondary btn-sm float-right"><i className="mdi mdi-cart-outline"></i> Add To Cart</button>
                               :  <button type="button" className="btn btn-secondary btn-sm float-right"
                               onClick={() => add_to_cart(subItm.productid)} 
                               ><i className="mdi mdi-cart-outline"></i> Add To Cart</button>
                              }
                                 <p className="offer-price mb-0">₹{subItm.selling_price} <i className="mdi mdi-tag-outline"></i><br/><span className="regular-price">₹{subItm.original_price}</span></p>
                              </div>
                        
                        </div>
                        
                     </div>
                      ) }  ) 
                     }
                    </div>
                    </>
                  }


{suggestionProduct.length>0&&        
                
                  
                     <div className="row">  
                     <div className="col-md-11">
                     <div className="card card-body cart-table" style={{maxHeight:'340px'}}>
                     <div className="table-responsive">
                        <table className="table cart_summary"> 
                           <tbody>
                             
                           { 
           suggestionProduct.length > 0 && suggestionProduct.map(item => { 
                        return (    
                              <tr>
                                 <td className="cart_product"> <img className="img-fluid" src={`${BASE_URL}assets/stores/original/${item.logo_image}`} alt="Vendor"/></td>
                                 <td className="cart_description">
                                    <h5 className="product-name">{item.productname}</h5>  
                                    <h6><strong><span className="mdi mdi-approval"></span> {'Store :'+item.store_name}</strong></h6>
                                    <h7><strong><span className="mdi mdi-approval"></span> {'Branch :'+item.branch_branch_name}</strong></h7>
                                </td> 
                                <td>
                                {(localStorage.getItem("userToken") == null) ?
                                 <button type="button"  data-target="#bd-example-modal" data-toggle="modal" className="btn btn-secondary btn-sm float-right"><i className="mdi mdi-cart-outline"></i> Add To Cart</button>
                               :  <button type="button" className="btn btn-secondary btn-sm float-right"
                               onClick={() => suggestionProduct_add_to_cart(item.productid,item.id,item.branch_id)} 
                               ><i className="mdi mdi-cart-outline"></i> Add To Cart</button>
                              }
                                </td>
                              </tr> 
                                 )
                              }
                              )
            
                           }
                           </tbody>
                          
                        </table>
                        </div></div>
                        <br/>
                        {/* <h2>Recent Searches</h2> */}
                     </div>
                     </div>
                    }

               </div>
            </div>
         </div>
      </section>
  
     
      
   <Footer/>
     
     </React.Fragment>
    )
}
